
import {
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:modelValue", "update:isClear", "change"],
  props: {
    modelValue: {
      required: true
    },
    type: {
      default: "csv" || " xlsx", // csv, xlsx
      type: String
    },
    isClear: {
      default: false,
      type: Boolean
    },
    isDraggable: {
      default: true,
      type: Boolean
    },
    maxRows: {
      default: 100000,
      type: Number
    },
    maxSize: {
      default: 256, // size in MB
      type: Number
    },
    url: {
      default: "",
      type: String
    },
    name: {
      default: "",
      type: String
    },
    statusReset: {
      default: false,
      type: Boolean
    },
    isMax: {
      default: true,
      type: Boolean
    },
    customClass: {
      default: "py-16",
      type: String
    },
    caption: {
      default: "",
      type: String
    },
    allowed: {
      default: "",
      type: String
    },
    changeCustom: {
      default: false,
      type: Boolean
    },
    showPreviewImage: {
      default: false,
      type: Boolean
    },
    isShowRemarkError: {
      default: true,
      type: Boolean
    },
    titleChange: {
      default: "Ubah File",
      type: String
    }
  }
})
export default class FilePreviewForum extends Vue {
  props: any = this.$props;
  fileName = "";
  isDataValidate = false;
  isDataRemoved = false;
  loadingUpload = false;
  isDragFile = false;

  $refs!: {
    file: HTMLFormElement;
  };

  onUploadCsv(files: any) {
    // set isClear to false
    if (this.props.isClear) this.$emit("update:isClear", false);

    if (files && files.name.split(".").pop() === "csv") {
      this.setValue(files);

      const reader = new FileReader();
      reader.readAsBinaryString(files);
      reader.onload = () => {
        const arrData = reader.result?.toString().split("\n");
        arrData?.pop();
        this.isDataValidate = !(
          arrData && arrData.length <= this.props.maxRows + 1
        );
      };
    }
    this.isDataValidate = true;
  }

  onUploadExcel(files: any) {
    this.loadingUpload = true;

    // set isClear to false
    if (this.props.isClear) this.$emit("update:isClear", false);

    const reader = new FileReader();

    if (files && files.name.split(".").pop() === "xlsx") {
      reader.onload = async e => {
        const data = e.target?.result;
        let countRows = 0;
        const XLSX = await import("xlsx");
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetNameList = workbook.SheetNames;
        for (const valueSheetName of sheetNameList) {
          const worksheet: any = workbook.Sheets[valueSheetName];
          const parsedData: any = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            blankrows: false
          });
          countRows += parsedData.length;
        }
        this.isDataValidate = countRows > this.props.maxRows + 1;
        if (!this.isDataValidate) this.setValue(files);
        this.loadingUpload = false;
      };
      reader.readAsBinaryString(files);
    }
    this.isDataValidate = true;
    this.loadingUpload = false;
  }

  onUploadPDFDocument(files: any) {
    if (
      files &&
      files.name
        .split(".")
        .pop()
        .match(/^pdf$|^doc$|^docx$/gi)
    ) {
      if (files.size <= 1024 * 1024 * this.props.maxSize) this.setValue(files);
      else this.isDataValidate = true;
    } else this.isDataValidate = true;
  }


  setDragAndDrop(e: any, value: boolean) {
    this.isDragFile = value;
    e.preventDefault();
  }

  isFileImage = false;
  fileImagePreview: any = "";

  setValue(file: any) {
    this.fileName = file.name;
    this.$emit("update:modelValue", file);
    this.$emit("change", file);
    this.isDataValidate = false;
    this.isDragFile = false;
    this.isFileImage = false;
    if (file.type.includes("image")) {
      this.isFileImage = true;
      this.fileImagePreview = URL.createObjectURL(file);
    }
  }

  onSubmitClick(e: any) {
    try {
      const file = e.target.files;
      const type = file[0]?.name.split(".").pop();

      if (
        file.length > 0 &&
        (this.props.type.includes(type) || this.props.allowed.includes(type))
      ) {
        // Click
        const clickFile = e.target.files;
        this.isDataRemoved = false;

        if (!this.props.allowed && this.props.type === "csv")
          return this.onUploadCsv(clickFile[0]);
        else if (!this.props.allowed && this.props.type === "xlsx")
          return this.onUploadExcel(clickFile[0]);
        else if (type.match(/^pdf$|^doc$|^docx$/gi))
          return this.onUploadPDFDocument(clickFile[0]);
        else if (this.props.allowed.includes("image"))
          this.onUploadImage(clickFile[0]);
      } else {
        this.$emit("update:modelValue", "");
        if (file.length === 0) {
          this.isDataRemoved = true;
        } else {
          this.isDataValidate = true;
        }
      }
    } catch (err) {
      this.$emit("update:modelValue", "");
      this.isDataValidate = true;
    }
  }

  // Drag File
  onSubmitDrag(e: any) {
    e.preventDefault();
    try {
      if (e.dataTransfer.files.length > 0) {
        const dragFile = e.dataTransfer.files;
        const typeDrag =
          dragFile && dragFile[0].name && dragFile[0].name.split(".").pop();

        if (dragFile && typeDrag === "csv")
          return this.onUploadCsv(dragFile[0]);
        else if (dragFile && typeDrag === "xlsx")
          return this.onUploadExcel(dragFile[0]);
        else if (dragFile && typeDrag === "pdf")
          return this.onUploadPDFDocument(dragFile[0]);
        else this.setDefault();
      } else this.setDefault();
    } catch (err) {
      this.setDefault();
    }
  }

  setDefault() {
    this.$emit("update:modelValue", "");
    this.fileName = "";
    this.isDataValidate = false;
    this.loadingUpload = false;
    this.isDragFile = false;
  }

  // Watch isClear changed
  updated() {
    if (this.props.isClear) this.setDefault();
  }

  // Ellipsis String
  ellipsisString(value: string) {
    return ellipsisString(value, 50);
  }

  onUploadImage(file: any) {
    const type = file.name.split(".").pop();
    if (type === "jpg" || type === "jpeg" || type === "png") {
      if (file.size <= 1024 * 1024 * this.props.maxSize) this.setValue(file);
      else this.isDataValidate = true;
    } else this.isDataValidate = true;
  }
}
